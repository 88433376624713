.markdown {
  word-wrap: break-word;
  /* font-size: 1.2em; */
  font-size: calc(5px + 1em);
  width: 100%;
}

.markdown h1,
h2 {
  /* margin: 0; */
  margin-top: 0.5em;
  margin-bottom: 0.25em;
}

.markdown h1 {
  font-size: 1.6em;
}

.markdown h2 {
  font-size: 1.4em;
}

.markdown p {
  margin: 0.4em 0;
}

.markdown ul,
ol {
  padding-left: 1em;
  /* margin: 1em 0; */
  margin: 0;
}

.markdown ol li {
  margin-top: 0.5em;
}


.markdown blockquote {
  padding: 0.1em 0.5em;
  /* background-color: #f1f1f1; */
  /* background-color: #ededed; */
  border-left: 6px solid #bbb;
  margin: 0.4em 0;
}

.markdown img {
  max-width: 100%;
}

/* 内嵌代码 */
/* .markdown p code { */
.markdown code {
  padding: 0.1em 0.25em;
  background-color: #dfdfdf;
  border-radius: 4px;
}

/* 大段代码块 */
.markdown pre {
  /* background-color: #f1f1f1; */
  border-radius: 5px;
  /* padding: 0.2em 0.4em; */
  white-space: pre-wrap;

  display: block;
  overflow-x: auto;
  padding: 0.4em 0.8em;
  /* padding: 3px 5px; */
  /* background: var(--bar-color); */
  background: #1e1e1e;
  color: #dcdcdc;
}

.markdown pre code {
  padding: 0;
  line-height: 1.2;
  background: #1e1e1e;
  /* background-color: #f1f1f1; */
  /* border-radius: 5px; */
  margin: 1em 0;
}

.markdown a {
  color: #1565C0;
  /* color: #1976D2; */
  /* color: #1E88E5; */
}

.markdown a:hover {
  color: var(--main-color);
}

.markdown del {
  opacity: 0.5;
  /* text-decoration: line-through 1px */
}

.article-container {
  position: relative;
}

.arcticle-list {
  /* margin-top: calc(-60px - 10px - 0.8em); */
  width: 100%;
  /* position: relative;
  left: 0;
  right: 0;
  margin: 0 auto; */
  margin-bottom: 2.2em;
  /* margin-bottom: 40px; */
  /* display: flex;
  flex-direction: column;
  align-items: flex-start; */
}

.article-link {
  text-decoration: none;
}

.article-card {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1em;
  /* margin: 2em 0; */
  /* padding: 0.8em; */
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* box-shadow: 0 6px 5px -5px rgba(0, 0, 0, 0.3); */
  cursor: pointer;
  color: #000;
  background-color: #fafafa;
  /* background-color: #282c34; */
}

.detail-card {
  margin-top: calc(-20px);
  /* margin-top: calc(-20px - (2.4em - 1.5em) / 2); */
  /* margin-top: calc(-2.6em - 0.8em - 0.5em); */
  /* margin-top: calc(-60px - 10px); */

  box-sizing: border-box;
  width: 100%;
  /* margin: 2em 0; */
  /* padding: 0.8em; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* color: #000; */
  /* background-color: #fafafa; */
}

/* .arcticle-card:active,
.article-card:hover {
  outline: 3px solid var(--main-color);
} */

.article-title {
  font-size: 1.5em;
  /* font-size: 26px; */
  font-weight: 400;
  margin: 0.5em 0;
}

/* .article-link:first-child .article-title {
  margin-top: 1em;
} */

.article-decription {
  text-align: left;
  font-size: 18px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  /* ... */
  white-space: nowrap;
  /* 文本不换行，这样超出一行的部分被截取，显示... */
}

.article-info {
  margin: 0.5em 0;
  display: flex;
  color: #757575;
  font-size: 16px;
}

.article-reading,
.article-like,
.article-date {
  margin-right: 2em;
}

@media (max-width: 800px) {

  .article-reading,
  .article-like,
  .article-date {
    margin-right: 2em;
  }

  .article-info {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .article-reading {
    display: none;
  }
  .detail-card {
    margin-top: 1.6em;
  }
}

.btn-edit {
  margin-left: auto;
}

.btn-delete {
  margin-left: 20px;
}

/* .save {
  position: absolute;
  right: 0;
  top: 0;
} */

.tag-select {
  margin: 10px 0;
}

.article-decription img {
  display: block;
  margin: 20px 0;
  width: 100%;
}

textarea {
  word-wrap: normal;
}

/* .image-add {

} */

/* .upload-form {
  z-index: 999;
} */

.upload-card {
  width: 2.3em;
  height: 2.3em;
  /* position: absolute; */
  position: relative;
  margin-left: 0 !important;
}

.upload-icon {
  width: 100%;
}

.image-input {
  width: 100%;
  height: 100%;
}


.image-input {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%;
  height: 100%; */
  font-size: 0; /* 设置0才能使用cursor: pointer*/
  opacity: 0;
  cursor: pointer;
}
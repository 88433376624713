
.login {
  position: absolute;
  top: 30%;
}

.login-title {
  font-size: 30px;
  margin-bottom: 20px;
}

.login, .login-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login-group:first-child {
  margin-bottom: 14px;
}

.login-input {
  margin: 7px 0;
  border-radius: 5px;
  padding: 7px;
  border: 1px solid #9e9e9e;
}

.login-input:focus {
  outline: 1px solid var(--main-color);
  border-color: var(--main-color);
}

.login-button {
  margin-top: 16px;
  width: 100%;
  background-color: var(--main-color);
  padding: 6px 10px;
  border: none;
  border-radius: 5px;
}

.login-button:active {
  background-color: #FFB300;
}

.login-input, .login-button {
  padding: 7px;
  height: 40px;
}

.login-input::placeholder {
  color: #9e9e9e;
}
/* .calendar-grid */

.calendar {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(5, 20%);
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
  /* grid-template-rows:  1fr 1fr 1fr 1fr 1fr; */
  /* height: 800px; */
  /* margin: 0; */
}

.calender-container {
  padding: 1.6em calc(0.5em + 4vw);
 /* padding-left: 5em;
 padding-right: 5em; */
}

.cell {
  
  /* display: inline-block; */
  /* width: calc(100% / 7); */
  /* 配合使用使高度等于宽度 */
  height: 0; 
  line-height: 0;
  /* height: 100%; */
  padding-bottom: 100%;
  /* padding-bottom: calc(100% / 7); */
  text-align: center;
  color: var(--bg-color);
  font-size: 1.4rem;
}

.cell:hover, .cell:active {
  color: var(--main-color);
}

  /* box-sizing: border-box; */
/* * {
  margin: 0;
  padding: 0;
} */

:root {
  font-size: calc(0.5em + 1vw);
}

body {
  margin: 0;
  padding: 0;
  --main-color: #FFC107;
  --bar-color: #282c34;
  --footer-h: 40px;
  /* --opacity-color: rgba(255,193,7, 0.6); */
  /* overflow-y: scroll; */
  /* position: absolute; */
  /* https://www.ucloud.cn/yun/114228.html#:~:text=%E9%A1%B5%E9%9D%A2%E6%BB%9A%E5%8A%A8%E6%9D%A1%E9%80%A0%E6%88%90%E5%AE%BD%E5%BA%A6%E5%87%8F%E5%B0%8F%E7%9A%84%E5%9C%BA%E6%99%AF%E5%BE%88%E5%B8%B8%E8%A7%81%E4%BA%86%EF%BC%8C%E7%94%B1%E4%BA%8E%20div%20%E5%9D%97%E7%BA%A7%E5%85%83%E7%B4%A0%E7%9A%84%E6%B5%81%E5%8A%A8%E6%80%A7%EF%BC%8C%E5%85%B6%E5%AE%BD%E5%BA%A6%E9%BB%98%E8%AE%A4%E4%B8%BA100%25%E7%9A%84,body%20%E5%AE%BD%E5%BA%A6%EF%BC%8C%E4%BD%86%E6%98%AF%E5%BD%93%E5%AE%B9%E5%99%A8%E7%9A%84%E9%AB%98%E5%BA%A6%E8%B6%85%E8%BF%87%E8%A7%86%E5%8F%A3%E5%AE%BD%E5%BA%A6%E6%97%B6%E5%80%99%EF%BC%8C%E9%A1%B5%E9%9D%A2%E5%B0%B1%E4%BC%9A%E5%87%BA%E7%8E%B0%E6%BB%9A%E5%8A%A8%E6%9D%A1%EF%BC%8C%E8%BF%99%E4%B8%AA%E6%BB%9A%E5%8A%A8%E6%9D%A1%E7%9A%84%E5%AE%BD%E5%BA%A6%E5%B0%B1%E4%BC%9A%E6%8C%A4%E5%8E%8B%20body%20%E7%9A%84%E5%8F%AF%E7%94%A8%E5%AE%BD%E5%BA%A6%EF%BC%8C%E4%B9%9F%E5%B0%B1%E6%98%AF%E4%BC%9A%E6%8C%A4%E5%8E%8B%E6%88%91%E4%BB%AC%E7%9A%84%E5%AE%B9%E5%99%A8%E7%9A%84%E5%AE%BD%E5%BA%A6%EF%BC%8C%E9%80%A0%E6%88%90%E9%A1%B5%E9%9D%A2%E6%99%83%E5%8A%A8%E7%9A%84%E7%8E%B0%E8%B1%A1%EF%BC%8C%E5%BE%88%E4%B8%8D%E5%8F%8B%E5%A5%BD%EF%BC%8C%E4%B8%8B%E9%9D%A2%E5%B0%B1%E6%9D%A5%E6%8E%A2%E8%AE%A8%E4%B8%8B%E5%A6%82%E4%BD%95%E8%A7%A3%E5%86%B3%E8%BF%99%E4%B8%AA%E6%BB%9A%E5%8A%A8%E6%9D%A1%E7%9A%84%E9%97%AE%E9%A2%98%E3%80%82 */
}

a {
  cursor: pointer;
}

.App {
  position: relative;
  min-height: 100vh;
  /* max-width: 1000px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */

  /* background-color: #FFF; */
  background-color: #eee;
  /* background-color: #f5f5f5; */
  /* background-color: #FAFAFA; */
  /* background-color: #bdbdbd; */
  /* background-color: #282c34; */
}


.header {
  box-sizing: border-box;
  position: sticky;
  top: 0;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  /* font-size: calc(6px + 1vw); */
  font-size: calc(16px + 1vmin);
  /* margin: 0 calc(10px + 2vmin); */
  padding: 0 calc(40px + 10vmin);
  /* border: 1px solid red; */
  /* font-size: calc(10px + 2vmin); */
  color: #FAFAFA;
  z-index: 999;
}

.header, .footer {
  background-color: var(--bar-color);
  /* color: #fff; */
}

.header-bar {
  display: flex;
  margin-left: auto;
  /* margin-right: 26px; */
  margin-right: calc(1px + 3vmin);
  /* align-items: stretch; */
  line-height: 60px;
}

.header-bar a {
  display: inline-block;
  text-decoration: none;
  /* font-size: calc(6px + 2vmin); */
  /* font-size: 21px; */
  font-weight: 300;
  /* padding: 0 calc(2px + 1vmin); */
  padding: 0 16px;
  /* border: 1px solid red; */
}

.header-bar a:hover {
  color: var(--main-color);
}

.header-bar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}

.header-bar li {
  height: 60px;
  box-sizing: border-box;
}

.header-bar li.active {
  color: var(--main-color);
  border-bottom: 4px solid var(--main-color);
}

/* .header-bar li {
  border: 1px solid red;
} */

.header-bar li a {
  color: inherit;
}

.home-link {
  color: var(--main-color);
  font-size: calc(18px + 1vmin);
  /* color: #61dafb; */
  text-decoration: none;
}

.home-link:hover, .home-link:active {
  color: #eee;
}

.search-box {
  /* margin-left: auto; */
  box-sizing: border-box;
  width: 180px;
  height: 26px;
  padding: 2px 10px;
  border-radius: 3px;
  border: none;
  outline: none;
  font-size: calc(12px + 1vmin);
  background-color: #FAFAFA;
  /* font-weight: 300; */
}

.search-box::placeholder {
  color: #9e9e9e;
}


.search-box:focus, .search-box:hover {
  outline: 3.5px solid var(--main-color);
  border-color: var(--main-color);
}

.container {
  margin-bottom: var(--footer-h);
}


.footer {
  position: absolute;
  bottom: 0;
  height: var(--footer-h);
  width: 100%;
  color: #fff;
  z-index: 999;
  text-align: center;
  font-size: 0.9em;
  /* font-size: calc(10px + 2vmin); */
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-around; */
  justify-content: center;
  line-height: var(--footer-h);
  /* line-height: cal(var(--footer-h) / 3); */
}

.copyright {
  /* margin: 0 3em; */
  margin: 0 calc(1em + 4vmin);
}

.filing-link {
  text-decoration: none;
  color: inherit;
}

/* .btn-group-fixed { */
  .btn-group-sticky {
    position: fixed;
    right: calc(50vw - 450px + 1.6em);
    /* right: calc(50vw - 400px + 1.6em); */
    /* right: 20%; */
    left: auto;
    /* left: 80%;  */
    /* 似乎是sticky的问题，不能像absolute那样设置右边距
    设置左边距100可以让按钮靠在最右边，也不会超出范围
    right: 20px; */
    /* top: 5em;  */
    top: 80px; 
    /* top: 80px;  */
    /* 60 + 20 */
    /* top: 20px; */
    border: none;
    /* background-color: inherit; */
  }

@media (max-width: 900px) {
/* @media (max-width: 37em) { */
  .header-bar li.active {
    border-bottom: none;
  }
  .header-bar a {
    padding: 0 8px;
  }
  .search-box {
    width: 80px;
  }
  .header {
    padding: 0 calc(10px + 2vmin);
  }
  .article-reading,
  .article-like,
  .article-date {
    margin-right: 0.1em;
  }
  .filing-link, .footer .reading {
    display: none;
  }
  /* .btn-group-fixed { */
    .btn-group-sticky {
      /* right: 0; */
      right: 1.6em;
      /* right: 20%; */
    }    
}

.title404 {
  margin-bottom: 1.2em;
}

.btn-group {
  position: absolute;
  top: 1.2em; 
  right: 1.2em;
  border: none;
  background-color: inherit;
}

.btn-black {
  margin-right: 1em;
  /* margin-right: 20px; */
}

.btn-hidden {
  display: none;
}
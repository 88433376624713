
:root {
  font-size: calc(0.7em + 0.4vw);
  /* font-size: calc(0.5em + 0.5vw); */
  --btn-w: 4.8em;
  --btn-h: 2.4em;
  /* --btn-w: calc(0.5em + 10vmin); */
  /* --btn-h: calc(0.5em + 5vmin); */
  /* 80px */
  /* --btn-h: 38px; */
}

.container {
  box-sizing: border-box;
  width: 900px;
  min-height: calc(100vh - 60px - 40px);
  background: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.6em;
  /* padding: 0 1.6em; */
  /* padding: 20px; */
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}


/* @media (max-width: 37em) { */
@media (max-width: 800px) {
  .container {
    width: 100%;    
  }
}

.btn {
  display: inline-block;
  width: var(--btn-w);
  height: var(--btn-h);
  line-height: var(--btn-h);
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0.35em;
  color: var(--bar-color);
  /* color: #fff; */
  background-color: #FFC107;
  /* #FFC107 */
  /* #282c34 */
     /* #343A40; */
   /* #343A40; */
  box-shadow: 0 10px 8px -8px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  cursor: pointer;
  border: none;
  outline: none;
  text-align: center;
  /* 默认样式，button和其他组件的字体不同，导致外观不一致 */
  font-family: initial;
}

/* .btn:active, .btn:hover { */
  /* background-color: #FF9800 ; */
  /* background-color: #FF6F00; */
  /* opacity: 0.9; */
/* } */

.btn-black {
  color: #fff;
  background-color: #282c34;
}

.btn:active, .btn:hover, .btn-black:active, .btn-black:hover {
  background-color: #eee;
  color: #212121;
}
  /* background-color: rgba(52,58,64, 0.9);   */

.form-control {
  margin: 0.6em 0;
  /* margin: 10px 0; */
  width: 100%;
  font-size: 1.4em;
  border: none;
  outline: none;
  resize: none;
  background: inherit;
  /* overflow-y: hidden; */
  /* padding: 10px 20px; */
}

.title0 {
  font-size: 2.2em;
  /* color: #424242; */
  margin-bottom: 1em;
}

.title1 {
  font-size: 2em;
  /* border-bottom: 1px solid #212121; */
}

.title2 {
  font-size: 1.8em;
}

.page404 {
  margin: auto;
  font-size: 2.6em;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  text-decoration: none;
  border: none;
}

select {
  font-size: 1.2em;
  height: 2em;
  line-height: 1.2;
  padding: 0.2em;
  border: 1px solid #bbb;
  border-radius: 3px;
  text-align: left;
  /* float: left; */
  /* width: 100px; */
}

select:hover, select:active {
  border: 1px solid rgba(0, 0, 0, 0);
  outline: 2px solid var(--main-color);
}

.tag-container {
  position: relative;
  text-align: center;
}

.tag {
  /* position: absolute; */
  margin: auto;
}

.tag-input {
  margin: 0;
  /* font-size: 1rem; */
  height: 2.5rem;
}

.tag-group {
  /* margin: 0.8em 0; */
  width: 100%;
  left: 0;
  /* top: 20px; */
  display: flex;
  justify-content: space-between;
}

.tag-card {
  background-color: #eee;
  margin: 0.8em;
  border-radius: 0.35em;
}

.tag-item {
  display: inline-block;
  /* font-size: 25px; */
  font-size: 1.5em;
  height: 1.8em;
  /* height: 40px; */
  line-height: 1.8em;
  width: 11.5em;
  /* width: 300px; */
  color: var(--bar-color);
  /* margin: 2px; */
  /* padding-left: 10px; */
  /* text-align: left; */
}

.tag-card:active, .tag-card:hover {
  outline: 3px solid var(--main-color);
  border-radius: 4px;
}

.tag-title {
  font-size: 1.8em;
  color: #616161;
  margin-bottom: 1em;
}

.title-stressed {
  color: #000;
}

.tag-link:link, .tag-link:visited, .tag-link:active{
  color: #616161;
}
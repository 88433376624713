.miniapp-container {
  align-items: center;
  text-align: center;
  background-color: #fff;
  justify-content: center;
  /* margin: auto; */
}

.miniapp-logo {
  height: calc(240px + 10vmin);
  /* height: 40vmin; */
  pointer-events: none;
  margin-bottom: 20px;
}

.miniapp-title {
  font-size: 21px;
  margin-bottom: 0.2em;
}

.miniapp-description {
  color: #616161;
}

.stressed {
  color: var(--main-color);
}